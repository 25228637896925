import type { ContractMetricsValidator, ContractValidator } from '@trustblock/validators/contract.validators';
import { type CountByChain, SpecialChain } from './chain.types';
import type { DistributedPick } from './global.types';

export enum ContractType {
  OnChain = 'on_chain',
  OffChain = 'off_chain',
  Secret = 'secret'
}
export const contractTypes = Object.values(ContractType);

export type ContractsCountByChain = CountByChain & {
  [SpecialChain.OffChain]: number;
  [SpecialChain.Secret]: number;
  total: number;
};
export type ContractsCountByChainKey = keyof ContractsCountByChain;

export type ContractMetricsUpdate = ContractMetricsValidator;

type ContractPrivateMetrics = Pick<
  ContractMetricsValidator,
  'totalValue' | 'totalUsers' | 'totalTransactions' | 'evaluatedAt'
>;

export type ContractPrivate = DistributedPick<
  ContractValidator,
  | 'id'
  | 'projectId'
  | 'type'
  // on-chain
  | 'chain'
  | 'evmAddress'
  // off-chain
  | 'repositoryUrl'
  | 'repositoryFullUrlHash'
  | 'repositoryCommitHash'
  | 'repositoryFilePath'
  // secret
  | 'name'
> & {
  metrics: ContractPrivateMetrics;
  createdAt: number;
};
export type ContractPrivateDeterministic<T extends ContractType> = ContractPrivate & {
  type: T;
};

export type ContractPublic = DistributedPick<
  ContractValidator,
  | 'type'
  // on-chain
  | 'chain'
  | 'evmAddress'
  // off-chain
  | 'repositoryUrl'
  | 'repositoryCommitHash'
  | 'repositoryFilePath'
  // secret
  | 'name'
> & {
  createdAt: number;
};
export type ContractPublicDeterministic<T extends ContractType> = ContractPublic & {
  type: T;
};
