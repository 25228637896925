export enum Chain {
  Ethereum = 'ethereum',
  Polygon = 'polygon',
  Avalanche = 'avalanche',
  Bnbchain = 'bnbchain',
  Arbitrum = 'arbitrum',
  Optimism = 'optimism',
  Fantom = 'fantom',
  Zksync = 'zksync'
}

export enum SpecialChain {
  OffChain = 'offChain',
  Secret = 'secret'
}

export const chains = Object.values(Chain);

export type CountByChain = {
  [Chain.Arbitrum]: number;
  [Chain.Avalanche]: number;
  [Chain.Bnbchain]: number;
  [Chain.Ethereum]: number;
  [Chain.Fantom]: number;
  [Chain.Optimism]: number;
  [Chain.Polygon]: number;
  [Chain.Zksync]: number;
};
export type CountByChainKey = keyof CountByChain;
